<template>
  <div>
    <div class="content" id="pdfDom">
      <div class="main">
        <div class="home_page">
          <div style="font-size: 80px; text-align: center; margin-top: 250px">
            大学专业选择（文科）
          </div>
          <div style="font-size: 60px; text-align: center; margin-top: 30px">
            测评报告
          </div>
          <div style="font-size: 30px; text-align: center; margin-top: 150px">
            原著：[美]耶鲁大学心理系
          </div>
          <div
            style="
              font-size: 20px;
              text-align: center;
              margin-top: 200px;
              margin-bottom: 50px;
            "
          >
            <!-- 报告接收人：{{ test.test_name }} -->
          </div>
        </div>
        <!--这里是分页	-->
        <DIV id="page1" style="page-break-after: always"></DIV>
        <!--分页结束-->
      </div>
      <div class="main">
        <div class="body">
          <div class="border">
            <div class="title">报 告 摘 要</div>
            <div class="txt">
              <p style="text-indent: 2em; padding: 20px">
                概要包括气质类型、学科兴趣指数、职业兴趣指数以及门类适合度排序。
                将你的个性特征与学习各种专业所需要具备的特质进行对比后，应用心理测量方法评估出的你学习各个专业的适合情况，分数越高，意味着你在该专业上可发展性越强。通俗而言，专业适合度指数意味着：
                从你的兴趣、气质类型、性格特征来看，你更适合学习哪些专业。
              </p>
              <div class="n_title">专业适合度排序</div>
              <table style="width: 100%" class="tables1">
                <tr>
                  <td style="text-align: center">排名</td>
                  <td style="text-indent: 2rem">专业门类</td>
                  <td style="text-align: center">适合指数</td>
                </tr>
                <tr v-for="(item, i) in zhuanye" :key="i">
                  <td style="text-align: center">{{ i + 1 }}</td>
                  <td style="text-indent: 2rem">{{ item.BigClassName }}</td>
                  <td style="text-align: center">{{ item.MaxFen }}</td>
                </tr>
              </table>
              <div class="n_title">你的特质剖析</div>
              <div class="right">
                <span>低</span><span>较低</span><span>中等</span
                ><span>较高</span><span>高</span>
              </div>
              <div v-for="(item, i) in tzpx.Trait" :key="i">
                <div>
                  <div style="display: inline-block; width: 40%">
                    {{ item.Trait }}
                  </div>
                  <div style="display: inline-block; width: 56%">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="15"
                      :format="format"
                      :percentage="Number(item.TotalPoints * 4)"
                    ></el-progress>
                  </div>
                </div>
              </div>
              <div class="n_title">学科兴趣</div>
              <div>
                下面这份课程偏好柱状图符合你的喜好吗？以下分数仅代表喜好程度，不代表成绩好坏。
              </div>
              <div>
                <span style="font-weight: bold; margin-right: 100px"
                  >学科兴趣</span
                >
                <span style="margin-right: 100px">低</span
                ><span style="margin-right: 100px">|</span>
                <span style="margin-right: 100px">中</span>
                <span style="margin-right: 100px">|</span> <span>高</span>
              </div>
              <div v-if="xkxq">
                <div v-for="(item, keys) in xkxq" :key="keys">
                  <div>
                    <div style="display: inline-block; width: 20%">
                      {{ item.Subject }}
                    </div>
                    <div style="display: inline-block; width: 70%">
                      <el-progress
                        :text-inside="true"
                        :stroke-width="20"
                        :percentage="Number(item.Points)"
                      ></el-progress>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="tzpx.KeyWord">
                <div class="n_title">人格性质</div>
                <div style="font-weight: bold">
                  你的气质：{{ tzpx.Temperament }}
                </div>
                <div
                  style="display: inline-block;
    padding: 0 17px;
    background: #c4a7e9;
    color: white;
    border-radius: 30px;
    font-size: 12px;
    line-height: 20px;
    margin-right:15px
}"
                  v-for="(item, keyss) in tzpx.KeyWord.split('|')"
                  :key="keyss"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <!--这里是分页	-->
          <div id="page1" style="page-break-after: always"></div>
          <!--分页结束-->
        </div>
      </div>
      <div class="main">
        <div class="body">
          <div class="border">
            <div class="title">学科兴趣</div>
            <div class="txt">
              <p style="text-indent: 2rem; font-weight: bold; margin-top: 20px">
                追求兴趣是人生的内驱力之一，也是生命本身的意义。
              </p>
              <p style="text-indent: 2rem; font-weight: bold">
                对于一门学科，如果对它本身有兴趣，我们的学习效率会很高。对于要上大学选择专业的考生而言，要选择的学科门类很多，自然必须先了解：自己最喜欢哪些学科？这些学科是稳定的还是暂时的？
                从高中生这个年龄阶段来看，兴趣开始逐渐从广泛到狭窄，甚至有些人已经固定，兴趣的稳定性开始增强，大致倾向性已经比较明确。而学习每一种专业都和学生在高中阶段对各门学科的学习情况、对学科的感兴趣情况是相关的。如：生物感兴趣的学生，可能憧憬着学习生物科学、生态学或医学；对语文感兴趣的学生，可能会希望报考中文专业，等等。所以，根据学科兴趣来选择专业就非常重要，而且有较高参考价值。
              </p>
              <div
                style="font-size: 18px; margin-top: 50px; margin-bottom: 10px"
              >
                学科兴趣指数
              </div>
              <div v-for="(item, keys) in xkpx" :key="keys">
                <div>
                  <div
                    style="
                      display: inline-block;
                      width: 20%;
                      text-align: right;
                      padding-right: 25px;
                    "
                  >
                    {{ item.Subject }}
                  </div>
                  <div style="display: inline-block; width: 60%">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="20"
                      :format="format1"
                      :percentage="Number(item.Points)"
                    ></el-progress>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--这里是分页	-->
          <div id="page1" style="page-break-after: always"></div>
          <!--分页结束-->
        </div>
      </div>
      <div class="main">
        <div class="body">
          <div class="border">
            <div class="title">职业兴趣</div>
            <div class="txt">
              <div id="chart" style="width: 500px; height: 400px"></div>
              <div>
                <span style="margin-right: 10px; color: #10f7f7"
                  >R 实际，喜欢操作使用工具</span
                ><span>喜欢运用技能、操作方法和工具\机器打交道。</span>
              </div>
              <div>
                <span style="margin-right: 10px; color: #10f7f7">
                  A 喜欢感性创造</span
                ><span
                  >喜欢想象，并加以具体化地制作或创造某种东西，具有创造力。</span
                >
              </div>
              <div>
                <span style="margin-right: 10px; color: #10f7f7"
                  >E 喜欢控制与影响</span
                ><span>喜欢管理或经营活动，对事件或他人形成影响。</span>
              </div>
              <div>
                <span style="margin-right: 10px; color: #10f7f7"
                  >C 传统，喜欢计划与细节</span
                ><span
                  >喜欢有计划和规则，希望遵照既定的安排完成工作任务，关注细节或数据。</span
                >
              </div>
              <div>
                <span style="margin-right: 10px; color: #10f7f7"
                  >S 喜欢与人打交道</span
                ><span>融入社会，喜欢与人沟通、与人接触的活动。</span>
              </div>
              <div>
                <span style="margin-right: 10px; color: #10f7f7"
                  >I 喜欢钻研思考</span
                ><span>喜欢运用分析与推理，花工夫进行思考和深度研究。</span>
              </div>
            </div>
          </div>
          <!--这里是分页	-->
          <div id="page1" style="page-break-after: always"></div>
          <!--分页结束-->
        </div>
      </div>
      <div class="main">
        <div class="body">
          <div class="border">
            <div class="title">专业适合度大排行</div>
            <div class="txt">
              <div v-for="(item, key) in zyranking" :key="key">
                <div
                  style="
                    font-size: 20px;
                    margin-top: 50px;
                    font-weight: bold;
                    margin-bottom: 20px;
                  "
                >
                  {{ item.Class }}专业上的适合度整体排行
                </div>
                <div v-for="(val, k) in item.Subject" :key="k">
                  <div>
                    <div
                      style="
                        display: inline-block;
                        width: 35%;
                        text-align: right;
                        padding-right: 25px;
                      "
                    >
                      <div v-if="val.Points == 0" style="font-weight: bold">
                        {{ val.Name }}
                      </div>
                      <div v-else>
                        {{ val.Name }}
                      </div>
                    </div>
                    <div
                      style="display: inline-block; width: 50%"
                      v-if="val.Points != 0"
                    >
                      <el-progress
                        :text-inside="true"
                        :stroke-width="20"
                        :format="format1"
                        :percentage="Number(val.Points)"
                      ></el-progress>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--这里是分页	-->
        <div id="page1" style="page-break-after: always"></div>
        <!--分页结束-->
      </div>
      <div class="main">
        <div class="body">
          <div class="border">
            <div class="title">人格特质</div>
            <div class="txt">
              <p style="text-indent: 2rem; font-weight: bold; margin-top: 30px">
                世界管理学大师彼得德鲁克说：大部分人不知道他们的优势何在。如果你问他们，他们就会呆呆地看着你，或文不对题地大谈自己的具体知识。事实证明，即使在德鲁克如此断言的几十年后人们对自己的认识仍然很茫然。
              </p>
              <p style="text-indent: 2rem; font-weight: bold">
                显然人们的职业发展和许多因素相关，如体力、脑力状况、个人兴趣、个性特征、家庭环境、所处的大环境、就业市场、能力、技能、教育以及性别等等。但个性在职业发展确实提供了不同个性类型的人擅长扮演不同的角色，不同个性类型的人需要不同的工作氛围使他们的工作富有成效，并且开心愉快。
              </p>
              <p style="text-indent: 2rem; font-weight: bold">
                人格特质了解的目的不是像吉普赛女郎的水晶球告诉你你最合适那个工作，而是告诉你哪种特性工作适合你的，通常给你提供工作组群，而且更能帮助你挖掘出你个性全面因素，并知道你将这些因素运用到新的工作和新的环境中，这样你的职业适应性增强了。这在就业市场变化多端，人们越来越不可能在一个企业从一而终工作到退休，而是需要改变观念主动出击，掌握自己的职业发展轨迹，不断提升自我职业价值，这种指导性显得尤为重要。
              </p>
              <div style="margin-left: 20px; margin-top: 30px; font-size: 18px">
                你的气质类型: <span>{{ rgtz.Title }}</span>
                <div>关键词描述：{{ rgtz.DescChs }}</div>
                <div>典型人物：{{ rgtz.Author }}</div>
                <div>性格分析：</div>
                <div v-html="rgtz.Advantage" style="padding-left: 50px"></div>
                系统建议：
                <div v-html="rgtz.Inferiority" style="padding-left: 50px"></div>
                职业建议：
                <div
                  v-html="rgtz.SuitableField"
                  style="padding-left: 50px"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <!--这里是分页	-->
        <div id="page1" style="page-break-after: always"></div>
        <!--分页结束-->
      </div>
    </div>
    <div class="print" @click="doPrint()">
      <el-button type="primary" icon="el-icon-download">下载</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "interest_w_result",
  data() {
    return {
      zhuanye: [],
      tzpx: [],
      xkxq: [],
      rgxz: [],
      xkpx: [],
      zyxq: [],
      rgtz: [],
      zyranking: [],
    };
  },
  
  mounted() {
    this.ReportId = this.$route.query.id;
    this.$fecth
      .post("Appraisal/getResult", {
        id: this.ReportId,
        url: "http://www.apesk.com/lee/new/GetFitBigClass.asp",
      })
      .then((res) => {
        this.zhuanye = res;
      });
    this.$fecth
      .post("Appraisal/getResult", {
        id: this.ReportId,
        url: "http://www.apesk.com/lee/new/GetTrait.asp",
      })
      .then((res) => {
        this.tzpx = res;
      });
    this.$fecth
      .post("Appraisal/getResult", {
        id: this.ReportId,
        url: "http://www.apesk.com/lee/new/GetSubjectInterest.asp",
      })
      .then((res) => {
        this.xkxq = res;
        this.xkpx = res;
      });
    this.$fecth
      .post("Appraisal/getResult", {
        id: this.ReportId,
        url: "http://www.apesk.com/lee/new/GetRadar.asp",
      })
      .then((res) => {
        this.zyxq = res;
        let leftData = [];
        let dataData = [];
        res.Data.forEach((item) => {
          let param = {
            name: item.Code + item.TypeName + item.Points,
            max: 10,
          };
          leftData.push(param);
          dataData.push(item.Points);
        });
        let option = {
          radar: {
            indicator: leftData,
          },
          series: [
            {
              type: "radar",
              data: [
                {
                  value: dataData,
                },
              ],
            },
          ],
        };
        let echarts = require("echarts");

        let myChart = echarts.init(document.getElementById("chart"));
        myChart.setOption(option);
      });
    this.$fecth
      .post("Appraisal/getResult", {
        id: this.ReportId,
        url: "http://www.apesk.com/lee/new/get_mbtiall_json.asp",
      })
      .then((res) => {
        this.rgtz = res;
      });
    //专业大排行
    this.$fecth
      .post("Appraisal/getResult", {
        id: this.ReportId,
        url: "http://www.apesk.com/lee/new/GetProFitness2.asp",
      })
      .then((res) => {
        this.zyranking = res;
        console.log(this.zyranking);
      });
  },
  methods: {
    doPrint() {
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      this.getPdf("#pdfDom");
    },
    format(item) {
      return item / 4;
    },
    format1(item) {
      return item;
    },
  },
};
</script>

<style scoped lang='less'>
.print {
  position: fixed;
  bottom: 100px;
  right: 200px;
}
.content {
  background: white;
  max-width: 800px;
  margin: 0 auto;
}
.main {
  .home_page {
    background-image: linear-gradient(#8950d5, #bb85e7);
    color: white;
    overflow: hidden;
    margin-bottom: 30px;
  }
  .body {
    padding: 20px;
  }
  .border {
    padding: 20px;
    padding-bottom: 40px;
    border: 1px solid #c4a7e9;
    position: relative;
    margin-top: 57px;
    .title {
      position: absolute;
      top: -23px;
      left: 276px;
      height: 41px;
      width: 200px;
      background: #c4a7e9;
      text-align: center;
      line-height: 40px;
      color: white;
    }
  }

  .tables {
    border: 1px solid #c4a7e9;
  }

  .line div {
    padding: 6px;
    font-weight: bold;
    background-color: #ffffff;
  }
  .l {
    float: left;
  }
  .r {
    float: right;
    margin-bottom: 0px;
  }
}
.n_title {
  display: inline-block;
  text-indent: 2rem;
  position: relative;
  left: -21px;
  background: #c4a7e9;
  color: white;
  margin-top: 90px;
  margin-bottom: 20px;
  width: 300px;
}
.right {
  display: inline-block;
  width: 400px;
  span {
    width: 20%;
    text-align: center;
    display: inline-block;
  }
}
.txt {
  line-height: 30px;
}
#chart {
  width: 500px;
  height: 500px;
  margin: 100px auto 0;
}
.tables1 {
  margin-top: 10px;
  border-collapse: collapse;
  td {
    border: 1px solid rgb(173, 169, 169);
  }
}
</style>
